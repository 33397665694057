<template>
  <svg
    height="800px"
    viewBox="0 0 20 20"
    width="800px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    >
      <g
        fill="currentColor"
        transform="translate(-260.000000, -8079.000000)"
      >
        <g transform="translate(56.000000, 160.000000)">
          <path
            d="M216,7929.1047 C216,7930.2097 215.104,7931.1047 214,7931.1047 C212.896,7931.1047 212,7930.2097 212,7929.1047 C212,7927.9997 212.896,7927.1047 214,7927.1047 C215.104,7927.1047 216,7927.9997 216,7929.1047 L216,7929.1047 Z M222,7935.9997 C222,7936.5527 221.552,7936.9997 221,7936.9997 L207,7936.9997 C206.448,7936.9997 206,7936.5527 206,7935.9997 L206,7921.9997 C206,7921.4477 206.448,7920.9997 207,7920.9997 L221,7920.9997 C221.552,7920.9997 222,7921.4477 222,7921.9997 L222,7935.9997 Z M222,7918.9997 L206,7918.9997 C204.896,7918.9997 204,7919.8957 204,7920.9997 L204,7936.9997 C204,7938.1047 204.896,7938.9997 206,7938.9997 L222,7938.9997 C223.105,7938.9997 224,7938.1047 224,7936.9997 L224,7920.9997 C224,7919.8957 223.105,7918.9997 222,7918.9997 L222,7918.9997 Z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
