<template>
  <svg
    height="800px"
    viewBox="0 0 20 20"
    width="800px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    >
      <g
        fill="currentColor"
        transform="translate(-300.000000, -8079.000000)"
      >
        <g transform="translate(56.000000, 160.000000)">
          <path
            d="M253,7932.1047 C253,7933.2097 252.104,7934.1047 251,7934.1047 C249.896,7934.1047 249,7933.2097 249,7932.1047 C249,7930.9997 249.896,7930.1047 251,7930.1047 C252.104,7930.1047 253,7930.9997 253,7932.1047 L253,7932.1047 Z M259,7926.1047 C259,7927.2097 258.104,7928.1047 257,7928.1047 C255.896,7928.1047 255,7927.2097 255,7926.1047 C255,7924.9997 255.896,7924.1047 257,7924.1047 C258.104,7924.1047 259,7924.9997 259,7926.1047 L259,7926.1047 Z M262,7935.9997 C262,7936.5527 261.552,7936.9997 261,7936.9997 L247,7936.9997 C246.448,7936.9997 246,7936.5527 246,7935.9997 L246,7921.9997 C246,7921.4477 246.448,7920.9997 247,7920.9997 L261,7920.9997 C261.552,7920.9997 262,7921.4477 262,7921.9997 L262,7935.9997 Z M262,7918.9997 L246,7918.9997 C244.896,7918.9997 244,7919.8957 244,7920.9997 L244,7936.9997 C244,7938.1047 244.896,7938.9997 246,7938.9997 L262,7938.9997 C263.105,7938.9997 264,7938.1047 264,7936.9997 L264,7920.9997 C264,7919.8957 263.105,7918.9997 262,7918.9997 L262,7918.9997 Z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
