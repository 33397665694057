<template>
  <svg
    fill="none"
    height="152"
    viewBox="0 0 152 152"
    width="152"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Ellipse 23"
      opacity="0.3"
    >
      <path
        d="M72.7424 2.0332C34.3457 3.69584 3.53299 34.6075 2.02097 73.0485H72.8845L72.7424 2.0332Z"
        stroke="#000015"
        stroke-dasharray="8 8"
        stroke-width="2"
      />
      <path
        d="M2 78.3799C3.21994 117.194 34.3069 148.476 73.0385 150L72.8952 78.3799H2Z"
        stroke="#000015"
        stroke-dasharray="8 8"
        stroke-width="2"
      />
      <path
        d="M149.979 73.0485C148.455 34.3114 117.178 3.22017 78.3692 2L78.6535 73.0485H149.979Z"
        stroke="#000015"
        stroke-dasharray="8 8"
        stroke-width="2"
      />
      <path
        d="M78.9615 150C117.693 148.476 148.78 117.194 150 78.3799H78.6749L78.9615 150Z"
        stroke="#000015"
        stroke-dasharray="8 8"
        stroke-width="2"
      />
    </g>
  </svg>
</template>
