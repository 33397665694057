<i18n>
ru:
  randomize: Перемешать
ua:
  randomize: Перемішавши
us:
  randomize: Randomize
</i18n>

<template>
  <common-popover>
    <template #hoverable>
      <arora-button
        ignore-settings
        :class-name="className"
        :label="translate('randomCubeButton.randomize')"
        data-test-id="random-cube-btn"
        @click="() => randomize()"
      >
        <icon-dice-one
          v-if="currentNumber === 1"
          class="v-random-cube-icon"
        />
        <icon-dice-two
          v-else-if="currentNumber === 2"
          class="v-random-cube-icon"
        />
        <icon-dice-three
          v-else-if="currentNumber === 3"
          class="v-random-cube-icon"
        />
        <icon-dice-four
          v-else-if="currentNumber === 4"
          class="v-random-cube-icon"
        />
        <icon-dice-five
          v-else-if="currentNumber === 5"
          class="v-random-cube-icon"
        />
        <icon-dice-six
          v-else
          class="v-random-cube-icon"
        />
      </arora-button>
    </template>
    <template #content>
      {{ translate('randomCubeButton.randomize') }}
    </template>
  </common-popover>
</template>

<script setup lang="ts">
import { useCommon } from '@arora/common'

withDefaults(
  defineProps<{
    className?: string
  }>(),
  {
    className: 'v-btn-border'
  }
)

const emit = defineEmits({
  click: null
})

const { translate } = useI18nSanitized()
const { getRandomInt } = useCommon()

const currentNumber = ref<number>(-1)

function randomize(): void {
  randomizeCube()
  emit('click')
}

function randomizeCube(): void {
  const oldValue = currentNumber.value
  currentNumber.value = getRandomInt(6)

  if (oldValue === currentNumber.value) {
    randomizeCube()
  }
}

onMounted(() => {
  randomizeCube()
})
</script>

<style lang="scss">
.v-random-cube-icon {
  fill: none;
  width: 1.5rem;
  height: 1.5rem;
}
</style>
