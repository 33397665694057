<template>
  <svg
    height="800px"
    viewBox="0 0 20 20"
    width="800px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    >
      <g
        fill="currentColor"
        transform="translate(-380.000000, -8079.000000)"
      >
        <g transform="translate(56.000000, 160.000000)">
          <path
            d="M333,7926.1047 C333,7927.2097 332.104,7928.1047 331,7928.1047 C329.896,7928.1047 329,7927.2097 329,7926.1047 C329,7924.9997 329.896,7924.1047 331,7924.1047 C332.104,7924.1047 333,7924.9997 333,7926.1047 L333,7926.1047 Z M333,7932.1047 C333,7933.2097 332.104,7934.1047 331,7934.1047 C329.896,7934.1047 329,7933.2097 329,7932.1047 C329,7930.9997 329.896,7930.1047 331,7930.1047 C332.104,7930.1047 333,7930.9997 333,7932.1047 L333,7932.1047 Z M339,7926.1047 C339,7927.2097 338.104,7928.1047 337,7928.1047 C335.896,7928.1047 335,7927.2097 335,7926.1047 C335,7924.9997 335.896,7924.1047 337,7924.1047 C338.104,7924.1047 339,7924.9997 339,7926.1047 L339,7926.1047 Z M339,7932.1047 C339,7933.2097 338.104,7934.1047 337,7934.1047 C335.896,7934.1047 335,7933.2097 335,7932.1047 C335,7930.9997 335.896,7930.1047 337,7930.1047 C338.104,7930.1047 339,7930.9997 339,7932.1047 L339,7932.1047 Z M342,7935.9997 C342,7936.5527 341.552,7936.9997 341,7936.9997 L327,7936.9997 C326.448,7936.9997 326,7936.5527 326,7935.9997 L326,7921.9997 C326,7921.4477 326.448,7920.9997 327,7920.9997 L341,7920.9997 C341.552,7920.9997 342,7921.4477 342,7921.9997 L342,7935.9997 Z M342,7918.9997 L326,7918.9997 C324.896,7918.9997 324,7919.8957 324,7920.9997 L324,7936.9997 C324,7938.1047 324.896,7938.9997 326,7938.9997 L342,7938.9997 C343.105,7938.9997 344,7938.1047 344,7936.9997 L344,7920.9997 C344,7919.8957 343.105,7918.9997 342,7918.9997 L342,7918.9997 Z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
