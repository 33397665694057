<template>
  <svg
    height="800px"
    viewBox="0 0 20 20"
    width="800px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    >
      <g
        fill="currentColor"
        transform="translate(-340.000000, -8079.000000)"
      >
        <g transform="translate(56.000000, 160.000000)">
          <path
            d="M293,7926.1047 C293,7927.2097 292.104,7928.1047 291,7928.1047 C289.896,7928.1047 289,7927.2097 289,7926.1047 C289,7924.9997 289.896,7924.1047 291,7924.1047 C292.104,7924.1047 293,7924.9997 293,7926.1047 L293,7926.1047 Z M293,7932.1047 C293,7933.2097 292.104,7934.1047 291,7934.1047 C289.896,7934.1047 289,7933.2097 289,7932.1047 C289,7930.9997 289.896,7930.1047 291,7930.1047 C292.104,7930.1047 293,7930.9997 293,7932.1047 L293,7932.1047 Z M299,7926.1047 C299,7927.2097 298.104,7928.1047 297,7928.1047 C295.896,7928.1047 295,7927.2097 295,7926.1047 C295,7924.9997 295.896,7924.1047 297,7924.1047 C298.104,7924.1047 299,7924.9997 299,7926.1047 L299,7926.1047 Z M302,7935.9997 C302,7936.5527 301.552,7936.9997 301,7936.9997 L287,7936.9997 C286.448,7936.9997 286,7936.5527 286,7935.9997 L286,7921.9997 C286,7921.4477 286.448,7920.9997 287,7920.9997 L301,7920.9997 C301.552,7920.9997 302,7921.4477 302,7921.9997 L302,7935.9997 Z M302,7918.9997 L286,7918.9997 C284.896,7918.9997 284,7919.8957 284,7920.9997 L284,7936.9997 C284,7938.1047 284.896,7938.9997 286,7938.9997 L302,7938.9997 C303.105,7938.9997 304,7938.1047 304,7936.9997 L304,7920.9997 C304,7919.8957 303.105,7918.9997 302,7918.9997 L302,7918.9997 Z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
